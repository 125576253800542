<template>
  <div class="travel-plan-record">
    <div class="card">
      <van-row class="title">
        <van-col span="24">
          {{ data.title }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="24">
          授信等级：{{ data.level }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="16">货量（万吨）：{{ data.weight }}</van-col>
        <van-col span="8" @click="to(data.lineDetail)">航线明细
          <van-icon name="share-o"/>
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="16" @click="to2(info.crmCustomerid)">货量详情跳转
          <van-icon name="share-o"/>
        </van-col>
      </van-row>
    </div>
    <div>
      <van-row class="info-title">基本信息</van-row>
      <van-form @submit="editPlan()">
        <van-field v-if="info.target" autosize required type="textarea" label="目标" :rules="costomerForm.target"
                   placeholder="目标" v-model="info.target"></van-field>
        <van-field v-if="info.situation" autosize required type="textarea" label="现状" :rules="costomerForm.situation"
                   placeholder="现状"
                   v-model="info.situation"></van-field>
        <van-field v-if="info.plan" autosize required type="textarea" label="计划方案" :rules="costomerForm.plan"
                   placeholder="计划方案" v-model="info.plan"></van-field>
        <van-row>
          <van-field
              readonly
              required
              clickable
              label="走访类型："
              input-align="left"
              :value="info.interviewType"
              :rules="costomerForm.interviewType"
              placeholder="选择走访类型"
              @click="onClickType"
              label-width="6.5em"
          />
          <van-popup v-model="showInterviewTypePicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="interviewType"
                @cancel="showInterviewTypePicker = false"
                @confirm="onConfirmType"
                default-index="0"
            />
          </van-popup>
        </van-row>
        <van-field autosize required type="textarea" label="目标及方案：" :rules="costomerForm.goalPlan"
                   placeholder="目标及方案" label-width="6.5em" v-model="info.goalPlan"></van-field>
        <van-field v-if="info.remarks" autosize type="textarea" label="备注" placeholder="备注"
                   v-model="info.remarks"></van-field>

        <div v-if="(info.enterpriseType == '船方' && info.enterpriseNature == '船方') || (info.enterpriseType == '船方' && info.enterpriseNature == '船东') ">
          <van-row style="background:white">
            <van-field
                required
                readonly
                clickable
                label="船舶"
            >
              <template #input>
                <van-row>
                  <van-col span="24">
                    <el-select
                        v-model="vessel"
                        multiple
                        placeholder="请选择船舶"
                    >
                      <el-option
                          v-for="item in optionsVessel"
                          :key="item.val"
                          :label="item.text"
                          :value="item.text">
                      </el-option>
                    </el-select>
                  </van-col>
                </van-row>
                <span style="color: #289AE1" @click="showModal = true">新建船舶＋</span>
              </template>
            </van-field>
          </van-row>
        </div>

        <!--填写船舶信息-->
        <div>
          <Modal :visible="showModal" @close="showModal = false" ref="taskModal"
                 id="taskModal1">
            <!--            <van-divider class="label" contentPosition="center">请填写船舶信息</van-divider>-->
            <van-form @submit="saveVessel()">
              <van-field required label="船舶公司：" readonly placeholder="请输入船舶公司" label-width="6.5em"
                         v-model="shipInfo.vesselCompany"></van-field>
              <van-field required label="船舶名称：" placeholder="请输入船名" label-width="6.5em" :rules="costomerForm.vesselName"
                         v-model="shipInfo.vesselName"></van-field>
              <van-field label="租船人：" placeholder="请填写租船人" label-width="6.5em"
                         v-model="shipInfo.charterer"></van-field>
              <van-field label="船东信息：" placeholder="请填写船东信息" label-width="6.5em"
                         v-model="shipInfo.customerName"></van-field>
              <van-field label="船东联系人：" placeholder="请填写船东联系人" label-width="6.5em"
                         v-model="shipInfo.contacts"></van-field>
              <van-field label="载重吨：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.dwt"></van-field>
              <van-field label="船长：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.length"></van-field>
              <van-field label="满载吃水：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.draught"></van-field>
              <van-field label="舱口数：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.hatch"></van-field>
              <van-field label="建造年限：" placeholder="请填写建造年限" label-width="6.5em"
                         v-model="shipInfo.period"></van-field>
              <van-field label="主要航线：" placeholder="请填写主要航线" label-width="6.5em"
                         v-model="shipInfo.mainRoutes"></van-field>
              <van-field  label="船舶种类：" placeholder="请填写船舶种类" label-width="6.5em"
                          v-model="shipInfo.vesselType"></van-field>
              <van-field label="主要货种：" placeholder="请填写主要货种" label-width="6.5em"
                         v-model="shipInfo.mainGoods"></van-field>
              <!--              <van-field label="总舱容：" placeholder="请填写总舱容" label-width="6.5em"-->
              <!--                         v-model="shipInfo.toalCapacity"></van-field>-->
              <div class="submit">
                <van-button type="default" native-type="button"
                            @click="showModal=false, vesselInfo ={}">取消
                </van-button>
                <van-button type="info" native-type="submit">确定</van-button>
              </div>
            </van-form>
          </Modal>

        </div>



        <van-button v-if="isHidden" type="primary" size="large" native-type="submit">保存
        </van-button>
      </van-form>
    </div>
    <crm-tab-bar :tabpage="1"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import {getBulkCrmShipinfo, saveBulkShipinfotwo} from "../../api/shipInfo";
import Modal from './../../components/Modal/MyCustomerModal';
import {Toast} from "vant";

export default {
  name: "BulkCrmsTravelPlanRecord",
  components: {CrmTabBar, Modal},
  data() {
    return {
      shipInfo: {
        vesselCompany: '',
        vesselName: '',
        charterer: this.$store.getters.name,
        customerName: '',
        contacts: '',
        cooperationTimes: '',
        dwt: '',
        length: '',
        draught: '',
        hatch: '',
        period: '',
        mainRoutes: '',
        vesselType: '散杂货',
        mainGoods: '',
        toalCapacity: '',
        capacity: '',
      },
      vessel: [],
      showModal: false,
      optionsVessel: [],
      isHidden: false,
      theFocus: false,
      showPicker: false,
      data: {
        title: '',
        level: '',
        weight: '',
        benefit: '',
        lineDetail: '',
        planDetail: 'planDetail'
      },
      interviewType: [
        '业务类拜访', '综合经营情况拜访'
      ],
      info: {},
      costomerForm: {
        customerName: [
          {required: true, message: '请选择客户', trigger: 'blur'},
        ],
        target: [
          {required: true, message: '请填写目标', trigger: 'blur'},
        ],
        situation: [
          {required: true, message: '请填写现状', trigger: 'blur'},
        ],
        plan: [
          {required: true, message: '请填写计划方案', trigger: 'blur'},
        ],
        interviewType: [
          {required: true, message: '请填写走访类型', trigger: 'blur'},
        ],
        goalPlan: [
          {required: true, message: '请填写目标及方案', trigger: 'blur'},
        ],
        vesselName: [
          {required: true, message: '', trigger: 'blur'},
        ],
      },
      showInterviewTypePicker: false,
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    //监听客户名头
    'vessel': function () {
      console.log(this.vessel)
    },
  },
  methods: {
    to(to) {
      this.$router.push(to)
    },
    to2(to) {
      console.log(to)
      this.$router.push("/CustomerPayerTo/" + to)
    },
    formatter(time) {
      let nowdata = time == null ? new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;

      return nowtime;
    },
    editPlan(formName) {
      if((this.info.enterpriseType == '船方' && this.info.enterpriseNature == '船方') || (this.info.enterpriseType == '船方' && this.info.enterpriseNature == '船东')){
        if(this.vessel.length==0){
          this.$toast({
            message: '请完善船舶信息！',
            position: 'top'
          });
        }else{
          this.$ajax.post('/api/ajax/planVisit/updateBulkCrmPlanvisit.json',
              {
                customerName: this.info.customerName,
                travelPlanid: this.info.travelPlanid,
                crmCustomerid: this.info.crmCustomerid,
                travelType: this.info.travelType,
                titleName: this.info.titleName,
                titleId: this.info.titleId,
                travelDate: this.info.travelDate,
                plandateStart: this.info.plandateStart,
                plandateEnd: this.info.plandateEnd,
                target: this.info.target,
                situation: this.info.situation,
                plan: this.info.plan,
                remarks: this.info.remarks,
                interviewType: this.info.interviewType,
                goalPlan: this.info.goalPlan,
                modifiedPeople: this.$store.getters.userId,
                vessel: this.vessel
              }).then(res => {
            if (res.status == 200) {
              this.$message.success('修改成功！');
              setTimeout(() => {
                this.$router.replace({path: '/BulkCrmsTravelPlanDetail/' + this.info.titleId})
              }, 200);
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('修改失败！');
          })
        }
      }else{
        this.$ajax.post('/api/ajax/planVisit/updateBulkCrmPlanvisit.json',
            {
              customerName: this.info.customerName,
              travelPlanid: this.info.travelPlanid,
              crmCustomerid: this.info.crmCustomerid,
              travelType: this.info.travelType,
              titleName: this.info.titleName,
              titleId: this.info.titleId,
              travelDate: this.info.travelDate,
              plandateStart: this.info.plandateStart,
              plandateEnd: this.info.plandateEnd,
              target: this.info.target,
              situation: this.info.situation,
              plan: this.info.plan,
              remarks: this.info.remarks,
              interviewType: this.info.interviewType,
              goalPlan: this.info.goalPlan,
              modifiedPeople: this.$store.getters.userId,
              vessel: this.vessel
            }).then(res => {
          if (res.status == 200) {
            this.$message.success('修改成功！');
            setTimeout(() => {
              this.$router.replace({path: '/BulkCrmsTravelPlanDetail/' + this.info.titleId})
            }, 200);
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('修改失败！');
        })
      }

    },
    init() {
      this.$ajax.post('/api/ajax/planVisit/getBulkCrmPlanvisitList.json',
          {
            travelPlanid: this.$route.params.id,
            titleId: '',
            createdPeople: this.$store.getters.userId
          }).then(res => {
        if (res.status == 200) {
          if (res.data.length > 0) {
            this.vessel = res.data[0].vessel
            this.info = res.data[0]
            this.shipInfo.vesselCompany = res.data[0].customerName
            this.data.title = res.data[0].customerName
            this.data.lineDetail = '/LineDetail/' + res.data[0].customerName
            for (let i = 0; i < this.info.length; i++) {
              this.info[i].to = '/BulkCrmsTravelPlanRecord/' + this.info[i].travelPlanid
            }
            if (this.info.ykbStatus == '00') {
              this.isHidden = true;
            }
            //获取客户信息
            this.$ajax.post('/api/ajax/bulkCrmsCusList/getHlxy.json',
                {
                  customerName: res.data[0].customerName
                }).then(res => {
              if (res.status == 200) {
                if (res.data.length > 0) {
                  this.data.weight = res.data[0].hfDTO.weight + "万吨"
                  this.data.benefit = res.data[0].hfDTO.profit + "万元"
                  this.data.level = res.data[0].customerLevels
                }
              }
            }).catch(err => {
              console.log(err)
              this.$message.error('获取客户货量效益失败！');
            })

            if((res.data[0].enterpriseType == '船方' && res.data[0].enterpriseNature == '船方') || (res.data[0].enterpriseType == '船方' && res.data[0].enterpriseNature == '船东')){
              getBulkCrmShipinfo({
                shipInformationid: res.data[0].crmCustomerid
              }).then(res => {
                this.optionsVessel = res
              }).catch(err => {
                console.log(err)
              });
            }
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    onClickType() {
      this.showInterviewTypePicker = true
      // this.info.interviewType = ''
    },
    onConfirmType(picker, value, index) {
      console.log(picker, value, index);
      if(this.isHidden){
        this.info.interviewType = picker;
      }
      this.showInterviewTypePicker = false;
    },
    saveVessel(){
      saveBulkShipinfotwo({
        shipInformationid: this.info.crmCustomerid,
        vesselName: this.shipInfo.vesselName,
        charterer: this.shipInfo.charterer,
        vesselCompany: this.shipInfo.vesselCompany,
        cooperationTimes: this.shipInfo.cooperationTimes,
        dwt: this.shipInfo.dwt,
        length: this.shipInfo.length,
        draught: this.shipInfo.draught,
        hatch: this.shipInfo.hatch,
        period: this.shipInfo.period,
        mainRoutes: this.shipInfo.mainRoutes,
        vesselType: this.shipInfo.vesselType,
        mainGoods: this.shipInfo.mainGoods,
        toalCapacity: this.shipInfo.toalCapacity,
        capacity: this.shipInfo.capacity,
        createdPeople: this.$store.getters.userId
      }).then(res => {
        this.vessel.push(this.shipInfo.vesselName)
        let array={}
        array.val=this.info.crmCustomerid
        array.text = this.shipInfo.vesselName
        this.optionsVessel.push(array)
        this.clearShip()
        Toast.success('添加船舶成功！');
      }).catch(err => {
        console.log(err)
      });
    },
    clearShip(){
      this.showModal = false
      this.shipInfo = {
        vesselCompany: this.shipInfo.vesselCompany,
        vesselName: '',
        charterer: this.$store.getters.name,
        customerName: '',
        contacts: '',
        cooperationTimes: '',
        dwt: '',
        length: '',
        draught: '',
        hatch: '',
        period: '',
        mainRoutes: '',
        vesselType: '散杂货',
        mainGoods: '',
        toalCapacity: '',
        capacity: '',
      }
    },
  }
}
</script>

<style scoped>
.title {
  padding: 10px 5px;
  font-weight: 400;
  font-size: 18px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.info {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.travel-plan-record .card {
  background: #318EF5;
}

.info-title {
  padding: 10px 10px 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #AAA8A8;
  font-style: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
}

.submit {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.submit .van-button {
  width: 150px;
}
</style>

<style>
.travel-plan-record .el-input__inner {
  border: none;
  padding: 0 3px;
}

/* 定义过渡效果 */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active for <=2.1.8 */ {
  opacity: 0;
}
</style>